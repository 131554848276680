<template>
  <div class="access-page">
    <Logo class="access-page__logo" />
    <div class="access-page__wrapper">
      <h1 class="access-page__title">
        {{ $t("Reset password") }}
      </h1>
      <div class="access-page__description access-page__description--wide">
        {{ $t("Please enter code from e-mail and create a new password") }}
      </div>
      <div class="access-page__notifications">
        <AlertContainer type="auth" />
      </div>
      <form class="access-page__form">
        <div class="access-page__form-line">
          <app-input v-model="form.code" :error="errors.code" :label="$t('Code from e-mail')" />
        </div>
        <div class="access-page__form-line">
          <app-input
            v-model="form.password"
            :error="errors.password"
            :label="$t('New Password')"
            type="password"
          />
        </div>
        <div class="access-page__form-line">
          <app-input
            v-model="form.password_repeat"
            :error="errors.password_repeat"
            :label="$t('Confirm Password')"
            type="password"
          />
        </div>

        <app-button :loading="loading" type="submit" size="wide" @click="changePassword">
          {{ $t("Save changes") }}
        </app-button>
        <div class="access-page__register-account">
          {{ $t("Didn’t get recovery code? Click") }}
          <router-link :to="`${$utils.i18nLink('/forgot-password')}`">
            {{ $t("here") }}
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/ui/AppButton"
import AppInput from "@/components/ui/forms/TextField"
import Logo from "@/components/shared/Logo"
import AlertContainer from "@/components/shared/Alert"
import changePasswordForm from "@/mixins/changePasswordForm"

export default {
  name: "ChangePassword",
  components: {
    AppButton,
    AppInput,
    Logo,
    AlertContainer,
  },
  mixins: [changePasswordForm],
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.form.email = this.$route.query["email"]
    if (!this.form.email) {
      this.$router.push("/users/forgot-password")
    }
  },
  methods: {
    async changePassword() {
      if (!this.formValid()) {
        return false
      }
      try {
        this.loading = true
        await this.$store.dispatch("user/changePassword", this.form)
        await this.$router.push("/login")
        this.$alert({
          type: "success",
          message: this.$gettext("You have successfully changed your password."),
          layout: "auth",
          stacked: false,
        })
      } catch (request) {
        this.$alert({
          type: "error",
          message: this.$t("User was not found or deleted"),
          layout: "auth",
          stacked: false,
        })
      }
    },
  },
}
</script>
