import Vue from "vue"

const actions = {
  async download(app, data) {
    const dataParams = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != null && v !== "")
    )
    let url = `/invoices/report/csv`
    if (Object.keys(dataParams).length !== 0) {
      url += `?${new URLSearchParams(dataParams).toString()}`
    }
    return await Vue.axios.get(url)
  },
  async downloadReport(app, data) {
    return await Vue.axios.get(`/invoices/${data.supplier_uid}/${data.invoice_uid}/supplier_pdf`, {
      responseType: "blob",
    })
  },
  async downloadInvoicesReportPdf(app, data) {
    return await Vue.axios.get(
      `/invoices/report/${data.organization_uid}/invoices-pdf/${data.unique_id}`,
      {
        responseType: "blob",
      }
    )
  },
  async deleteInvoicesReportPdf(app, data) {
    return await Vue.axios.delete(
      `/invoices/report/${data.organization_uid}/invoices-pdf/${data.unique_id}`,
      {
        responseType: "blob",
      }
    )
  },
  async getStripeInvoice(app, data) {
    return await Vue.axios.get(
      `/invoices/${data.organization_uid}/${data.invoice_uid}/stripe_invoice`
    )
  },
  async downloadStripeInvoice(app, stripe_pdf_url) {
    return await Vue.axios.get(stripe_pdf_url, {
      responseType: "blob",
    })
  },
}
export default {
  namespaced: true,
  actions,
}
