<template>
  <div class="available-networks">
    <div class="available-networks--label">
      <label :for="id">{{ $t(label) }}</label>
    </div>
    <div class="available-networks--input" :class="{ error }">
      <Treeselect
        :id="id"
        v-model="value"
        :multiple="multiple"
        :options="options"
        :disabled="loading"
        :auto-load-root-options="false"
        :placeholder="null"
        :value-format="'object'"
        v-on="$listeners"
      >
        <label
          slot="option-label"
          slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
          :class="labelClassName"
        >
          <span class="available-networks--label-container">
            {{ node.label }}
            <span v-if="node.isRootNode" class="available-networks--rir">
              {{ node.isRootNode ? node.raw.inetnum_rir : "" }}</span
            >
            <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
          </span>
        </label>
        <div slot="value-label" slot-scope="{ node }">
          <span class="available-networks--value-container">
            {{ node.raw.cidr }}
            <span class="available-networks--rir available-networks--rir__left">
              {{ node.raw.inetnum_rir }}
            </span>
          </span>
        </div>
      </Treeselect>
      <validation-error v-if="error" :error="error" />
      <div v-if="loading" class="loading-holder">
        {{ $t("Please wait, building ip-blocks map") }}
        <loading></loading>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Documentation: https://vue-treeselect.js.org/
 */
import Treeselect from "@riophae/vue-treeselect"
import withCurrentOrganization from "@/mixins/withCurrentOrganization"
import ValidationError from "@/components/ui/forms/ValidationError"
import Loading from "@/components/shared/Loading.vue"

export default {
  name: "AvailableNetworks",
  components: { Loading, Treeselect, ValidationError },
  mixins: [withCurrentOrganization],
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "Select IP-blocks",
    },
  },
  data() {
    return {
      value: null,
      loaded: false,
      id: null,
      loading: false,
      options: null,
      orderUid: this.$route.query["orderUid"],
      contract: null,
    }
  },
  async created() {
    const organisation = this.currentOrganization.unique_id
    const data = this.$store.getters["networks/getAvailabilities"]
    this.options = data[organisation] ? data[organisation] : null
    if (!this.options) {
      await this.loadOptions()
    }
  },
  methods: {
    async loadOptions() {
      this.loading = true
      const organisation = this.currentOrganization.unique_id
      const response = await this.$store.dispatch("networks/loadAvailability", {
        organization_uid: organisation,
        order_uid: this.orderUid,
      })
      const data = this.$store.getters["networks/getAvailabilities"]
      if (this.orderUid) {
        this.options = response.data
      } else {
        this.options = data[organisation] ? data[organisation] : null
      }
      this.id = this.$utils.generateId()
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.available-networks {
  position: relative;

  &--label {
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 7px;

    &--rirs {
      margin-top: 7px;
    }
  }

  &--rir {
    font-size: 8px;
    background: var(--text-grey);
    color: #ffffff;
    padding: 2px 4px;
    border-radius: var(--default-border-radius);
    display: inline-block;
    transform: translateY(-10px);
    position: absolute;
    line-height: 1;

    &__left {
      left: -6px;
      transform: translateY(-12px);
    }
  }

  &--value-container {
    position: relative;
  }

  .loading-holder {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 30px;
    transform: translateY(50%);
  }
}
</style>
